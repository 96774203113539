<template>
  <div class="min-h-[50px] e_store-container min-h-screen my-10">
    <RikazGrid
      v-if="categoriesByCategoryStyle"
      name="material"
      :items="categoriesByCategoryStyle"
      margin="sm:-mr-3.5"
    >
      <template #card="{ forKey, data }">
        <StoresCategoryCard
          :key="forKey"
          :category="data"
          :active="false"
          @click="selectCategory"
        />
      </template>
    </RikazGrid>
  </div>
</template>

<script setup lang="ts">
import { useDomainCategoriesStore } from '~/store/domain_categories'

const { url } = useDomainHost()
const { store } = useDomainState()
const { categoriesByCategoryStyle } = useDomainCategoriesStore()
const { t } = useI18n()
const router = useRouter()

function selectCategory(id: number) {
  const localePath = useLocalePath()
  router.push(
    localePath({
      path: '/materials',
      query: { category_id: id.toString() }
    })
  )
}

useServerSeoMeta(
  useOgMeta(
    url + '/categories',
    t('categories'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('categories'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/categories')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, store.value?.name, url),
      useSeoBreadcrumbItem(2, t('categories'), url + '/categories')
    ])
  ]
})
</script>
